<template>
  <el-card class="iot-person public-page-style iot-search-header">
    <div slot="header">
      <el-form :inline="true" :model="getForm">
        <el-form-item>
          <el-input
            size="small"
            clearable
            v-model="getForm.serverName"
            placeholder="请输入服务器名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            size="small"
            clearable
            v-model="getForm.serverHost"
            placeholder="请输入服务器IP"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" plain @click="checkTable()"
            >查询</el-button
          >
        </el-form-item>
        <el-button
          type="primary"
          plain
          size="mini"
          class="iot-account-create"
          v-if="$store.state.permissionArr.indexOf('server:add') != -1"
          @click="addServer()"
          >新增</el-button
        >
      </el-form>
    </div>
    <el-table
      tooltip-effect="light"
      :data="tableData"
      stripe
      border=""
      style="width: 100%"
      :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
    >
      <el-table-column
        prop="serverId"
        width="100"
        label="服务器编号"
      ></el-table-column>
      <el-table-column
        prop="mqttName"
        width="170"
        label="MQTT名称"
      ></el-table-column>
      <el-table-column
        prop="serverName"
        width="170"
        label="服务器名称"
      ></el-table-column>
      <el-table-column
        prop="serverHost"
        width="170"
        label="服务器地址"
      ></el-table-column>
      <el-table-column prop="serverPort" label="端口号"></el-table-column>
      <el-table-column prop="topicPub" label="主题" width="150"  show-overflow-tooltip="true"></el-table-column>
      <el-table-column
        prop="pubQos "
        :formatter="pubName"
        label="主题类型"
      ></el-table-column>
      <el-table-column prop="topicSub" label="订阅"></el-table-column>
      <el-table-column
        prop="subQos"
        :formatter="subName"
        label="订阅类型"
      ></el-table-column>

      <!-- <el-table-column label="激活状态">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.serverState == 0"
            >已激活</el-tag
          >
          <el-tag type="info" v-else-if="scope.row.serverState == 1"
            >未激活</el-tag
          >
        </template>
      </el-table-column> -->
      <el-table-column
        prop="createTime"
        width="160"
        label="创建时间"
      ></el-table-column>
      <el-table-column
        prop="modifyTime"
        width="160"
        label="更新时间"
      ></el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            plain
            v-if="$store.state.permissionArr.indexOf('server:update') != -1"
            @click="editServer(scope.row.serverId)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            plain
            v-if="$store.state.permissionArr.indexOf('server:delete') != -1"
            @click="delServer(scope.row.serverId)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: right">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="getForm.current"
        :page-sizes="[10, 20, 30, 40]"
        :total="getForm.total"
        :page-size="getForm.size"
        layout="total, sizes, prev, pager, next, jumper"
        @prev-click="
          () => {
            $set(getForm, 'current', getForm.current - 1);
            getServerPage();
          }
        "
        @next-click="
          () => {
            $set(getForm, 'current', getForm.current + 1);
            getServerPage();
          }
        "
      >
      </el-pagination>
    </div>

    <el-dialog
    top="3%"
      :title="formTitle"
      :show-close="false"
      :visible.sync="serverDialog"
      :before-close="handleClose"
      :destroy-on-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
      width="30%"
      class="iot-server"
    >
      <el-form
        :model="serverForm"
        ref="serverRef"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="MQTT名称" prop="mqttName">
          <el-select v-model="serverForm.mqttId" placeholder="请选择">
            <el-option
              v-for="item in seletList"
              :key="item.mqttId"
              :label="item.mqttName"
              :value="item.mqttId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务器名称" prop="serverName">
          <el-input
            v-model="serverForm.serverName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="服务器地址" prop="serverHost">
          <el-input
            v-model="serverForm.serverHost"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="端口号" prop="serverPort">
          <el-input
            v-model="serverForm.serverPort"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="发布主题" prop="topicPub">
          <el-input v-model="serverForm.topicPub" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="发布质量" prop="pubQos">
          <el-radio-group v-model="serverForm.pubQos">
            <el-radio
              v-for="(item, index) in radio"
              :key="index"
              :label="item.value"
              >{{ item.names }}</el-radio
            >
            <!-- <el-radio :label="1">备选项</el-radio>
            <el-radio :label="2">备选项</el-radio> -->
          </el-radio-group>
        </el-form-item>

        <!-- <el-form-item label="订阅主题">
          <el-input v-model="serverForm.topicSub" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="订阅质量">
          <el-radio-group v-model="serverForm.subQos">
            <el-radio
              v-for="(item, index) in radio"
              :key="index"
              :label="item.value"
              >{{ item.names }}</el-radio
            >
            <el-radio :label="1">备选项</el-radio>
            <el-radio :label="2">备选项</el-radio>
          </el-radio-group> 
        </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain size="mini" @click="submitForm()"
          >确 定</el-button
        >
        <el-button type="info" plain size="mini" @click="serverDialog = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import { TABLE_HEADER_COLOR } from "../util/theme";
let that;
export default {
  name: "Server",
  filters: {},
  data() {
    return {
      radio: [
        {
          value: 0,
          names: "至多一次",
        },
      ],
      theme: { TABLE_HEADER_COLOR },
      serverDialog: false,
      tableData: [],
      seletList: [],
      getForm: {
        serverName: "",
        serverHost: "",
        size: 10,
        current: 1,
        total: 0,
      },
      serverForm: {
        mqttName: "",
        mqttId: null,
        serverId: "",
        serverName: "",
        serverHost: "",
        serverPort: "",
        topicPub: "",
        pubQos: 0,
        topicSub: "",
        subQos: 0,
      },

      isCreate: true,
      formTitle: "",
      rules: {
        serverName: [
          { required: true, message: "请输入服务器名称", trigger: "blur" },
        ],

        topicPub: [{ required: true, message: "请输入主题", trigger: "blur" }],
        pubQos: [
          {
            required: true,
            message: "至少选择一个主题类型",
            trigger: "change",
          },
        ],

        serverHost: [
          { required: true, message: "请输入服务器IP", trigger: "blur" },
        ],
        serverPort: [
          { required: true, message: "请输入服务器端口", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    //分页方法
    handleSizeChange(val) {
      that.getForm.size = val;
      that.getServerPage();
    },
    handleCurrentChange(val) {
      that.getForm.current = val;
      that.getServerPage();
    },
    pubName: function (row) {
      return this.radio[row.pubQos].names;
    },
    subName: function (row) {
      return this.radio[row.pubQos].names;
    },
    submitForm() {
      that.$refs["serverRef"].validate((valid) => {
        if (valid) {
          let _api = that.isCreate
            ? that.$api.server.createServer(that.serverForm)
            : that.$api.server.editServer(that.serverForm);
          _api.then((response) => {
            if (response.code == 200) {
              that.$refs.serverRef.resetFields();
              that.serverDialog = false;
              that.isCreate = true;
              that.getServerPage();
              that.$message({
                type: "success",
                message: response.msg,
              });
            }
          });
        }
      });
    },
    // getMqttName(mqttId){

    //     that.serverForm.mqttId=mqttId
    //     console.log(that.serverForm.mqttId);

    // },
    checkTable() {
      that.$set(that.getForm, "current", 1);
      that.getServerPage();
    },
    getServerPage() {
      that.$api.server.getServerPage(that.getForm).then((response) => {
        that.tableData = response.data.records;
        that.getForm.total = response.data.total;
      });
    },
    addServer() {
      that.formTitle = "新增服务器";
      that.serverDialog = true;
      that.$api.mqtt.getMqttList().then((response) => {
        that.seletList = response.data;
      });
      that.isCreate = true;
      that.serverForm = {
        mqttName: "",
        mqttId: null,
        serverId: "",
        serverName: "",
        serverHost: "",
        serverPort: "",
        topicPub: "",
        pubQos: 0,
        topicSub: "",
        subQos: 0,
      };
    },
    handleClose() {
      that.serverDialog = false;
    },
    editServer(serverId) {
      that.$api.server.getServer(serverId).then((response) => {
        if (response.code == 200) {
          that.serverForm = response.data;

          that.formTitle = "修改服务器";
          that.$api.mqtt.getMqttList().then((response) => {
            that.seletList = response.data;
          });

          that.serverDialog = true;
          that.isCreate = false;
        }
      });
    },
    delServer(serverId) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$api.server.delServer(serverId).then((response) => {
            if (response.code == 200) {
              that.$message({
                type: "success",
                message: response.msg,
              });
              that.getServerPage();
            }
          });
        });
    },
  },
  beforeCreate() {
    that = this;
  },
  mounted() {
    that.getServerPage();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.iot-account-create {
  float: right;
  margin: 10px;
}
.iot-account-permission {
  height: 387px;
}
.iot-search-header >>> .el-card__header {
  padding: 0 18px;
}
.iot-person >>> .el-select {
  width: 100%;
}
.iot-server >>> .el-dialog {
  height: 678px;
}
</style>
